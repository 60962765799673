import axios from 'axios';

import PositionsCache from './PositionsCache';
import { mapFromDataRaceToRace } from "./mappers/raceResultsMapper";
import { mapRaceLaptimes } from "./mappers/raceLaptimesMapper";

const API_RACE_DATA = process.env.REACT_APP_API_RACE_DATA;
const API_RACE_CLOCK = process.env.REACT_APP_API_RACE_CLOCK;
const API_RACE_RESULTS = process.env.REACT_APP_API_RACE_RESULTS;
const API_RACE_LAPTIMES = process.env.REACT_APP_API_RACE_LAPTIMES;
const API_RACE_WEATHER = process.env.REACT_APP_API_WEATHER;

const RaceService = {
    getRaceAndPositions: async function () {
        const promises = [
            getRaceData(),
            getRaceClock(),
            getRaceResults(),
            getRaceWeather(),
        ];
        const [raceData, raceClock, raceResults, raceWeather] = await Promise.all(promises);

        const race = mapFromDataRaceToRace(raceData, raceClock, raceResults, raceWeather);
        // console.log(race)
        return calculateInSegment(race);
        // return race;
    },

    getRaceLaptimesForDriver: async function (number) {
        const rawLaptimes = await getRaceLaptimes();
        const laptimes = mapRaceLaptimes(rawLaptimes)
        return laptimes[number];
    },

    getRaceResults,
};

function addCacheBuster(url) {
    const timestamp = new Date().getTime(); // Get current timestamp
    return `${url}?t=${timestamp}`;
}


async function getRaceData() {
    try {
        const urlWithCacheBuster = addCacheBuster(API_RACE_DATA);
        const response = await axios.get(urlWithCacheBuster);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch race data:', error);
        return null;
    }
}

async function getRaceClock() {
    try {
        const urlWithCacheBuster = addCacheBuster(API_RACE_CLOCK);
        const response = await axios.get(urlWithCacheBuster);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch race clock:', error);
        return null;
    }
}

async function getRaceResults() {
    try {
        const urlWithCacheBuster = addCacheBuster(API_RACE_RESULTS);
        const response = await axios.get(urlWithCacheBuster);
        PositionsCache.setPositions(response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch race results:', error);
        return null;
    }
}

async function getRaceLaptimes() {
    try {
        const urlWithCacheBuster = addCacheBuster(API_RACE_LAPTIMES);
        const response = await axios.get(urlWithCacheBuster);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch race lap times:', error);
        return null;
    }
}

async function getRaceWeather() {
    try {
        const urlWithCacheBuster = addCacheBuster(API_RACE_WEATHER);
        const response = await axios.get(urlWithCacheBuster);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch race weather:', error);
        return null;
    }
}

function calculateInSegment(race) {
    race.positions.forEach((position) => {
        const { race: { track: { segments, lastSegmentCompleted } } } = position;

        if (!segments || segments.length === 0) {
            // Handle the case where segments is undefined or empty
            // console.error('Segments array is undefined or empty');
            return; // Exit the current iteration
        }

        const lastSegmentIndex = segments.findIndex(segment => segment.name === lastSegmentCompleted);
        // Check if lastSegmentIndex is valid
        if (lastSegmentIndex === -1) {
            // console.error('Last segment completed not found in segments');
            return; // Exit the current iteration
        }

        const inSegmentIndex = (lastSegmentIndex) % segments.length;
        const nextSegment = segments[inSegmentIndex];

        // Check if nextSegment is defined before accessing its name
        if (nextSegment) {
            position.race.track.inSegment = nextSegment.name;
        } else {
            console.error('Next segment is undefined');
            // Handle the case where the next segment is undefined, perhaps by setting a default value or skipping
        }
    });

    return race;
}

export default RaceService;
